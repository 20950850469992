<!-- qa问题搜索没有结果 -->
<template>
  <div>
    <headBar />
    <searchModel />
    <div class="backGroundBg">
      <div class="con">
        <div class="backHomg" @click="$fun.routerToPage('/centro-de-ayuda')">
          Centro de ayuda
        </div>
        <div class="searchConBox">
          <leftMenu />
          <div class="searchBoxRight">
            <h1>
              No hay resultados para: {{ $route.query.s.replace("+", " ") }}
            </h1>
            <div class="divider"></div>
            <p>
              Lo sentimos. No hay ningún resultado para la búsqueda de
              {{ $route.query.s.replace("+", " ") }}
            </p>
            <p>
              Escribe la consulta de otra forma, o navega por las categorías del
              centro de ayuda para encontrar la respuesta que estás buscando.
            </p>
          </div>
        </div>
        <div class="hotProblems">
          <div class="proList">
            <h3>Preguntas principales:</h3>
            <p @click="$fun.routerToPage('/como-funciona-rakumart')">
              ¿Cómo funciona Rakumart?
            </p>
            <p @click="$fun.routerToPage('/que-ventajas-ofrece-rakumart')">
              ¿Qué ventajas ofrece Rakumart?
            </p>
            <p @click="$fun.routerToPage('/que-garantias-ofrece-rakumart')">
              ¿Qué garantías ofrece Rakumart?
            </p>
            <p @click="$fun.routerToPage('/como-hacer-un-pedido')">
              ¿Cómo hacer un pedido?
            </p>
          </div>
          <div class="proList">
            <h3>Sobre tus pedidos:</h3>
            <p @click="$fun.routerToPage('/donde-esta-mi-pedido')">
              ¿Dónde está mi pedido?
            </p>
            <p @click="$fun.routerToPage('/cuando-recibo-la-cotizacion')">
              ¿Cuándo recibo la cotización?
            </p>
            <p @click="$fun.routerToPage('/cuando-se-paga-el-envio')">
              ¿Cuándo se paga el envío?
            </p>
            <p @click="$fun.routerToPage('/puedo-comprar-muestras')">
              ¿Puedo comprar muestras?
            </p>
          </div>
        </div>
      </div>
    </div>
    <contactUs />
    <footbar />
  </div>
</template>
  <script>
import headBar from "../../../components/head/HomePageTop.vue";
import leftMenu from "./components/leftMenu.vue";
import footbar from "../../../components/foot/Foot.vue";
import searchModel from "./components/searchModel.vue";
import contactUs from "./components/contactUs.vue";
import pageData from "./unitl/pageData";
export default {
  data() {
    return {
      pageData,
      page: 1,
      searchArr: [],
      showList: [],
    };
  },
  components: {
    headBar,
    footbar,
    searchModel,
    leftMenu,
    contactUs,
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
  <style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.backGroundBg {
  background-color: white;
  padding-top: 20px;
  .con {
    width: 1000px;
    margin: 0 auto;
    .backHomg {
      font-size: 14px;
      color: #7a7a7a;
      padding: 10px 10px 24px;
      cursor: pointer;
    }
    .searchConBox {
      display: flex;
      .searchBoxRight {
        flex: 1;
        padding: 10px;
        h1 {
          font-size: 26px;
          font-weight: 500;
          line-height: 1;
          margin-bottom: 20px;
        }
        // 分割线
        .divider {
          height: 1px;
          background-color: #d5d5d5;
          margin-bottom: 20px;
        }
        p {
          font-size: 15px;
          line-height: 1.8em;
          margin-bottom: 14px;
        }
      }
    }
    .hotProblems {
      $modelWidth: 980px;
      width: $modelWidth;
      margin: 0 auto;
      padding: 50px 0;
      display: flex;
      justify-content: space-between;
      .proList {
        width: ($modelWidth / 2) - 10;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #e4e4e4;
        transition: background 0.3s, border 0.3s, border-radius 0.3s,
          box-shadow 0.3s;
        border-radius: 6px 6px 6px 6px;
        padding: 50px 50px 50px 50px;
        h3 {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 20px;
        }
        p {
          width: min-content;
          font-size: 16px;
          font-weight: 400;
          color: #54595f;
          margin-bottom: 9px;
          white-space: nowrap;
          position: relative;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0;
          }
          &:before {
            content: "●";
            font-size: 25px;
            height: 15px;
            line-height: 1;
            align-items: center;
            color: #1a289d;
          }
          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: #1a289d;
          }
          &:hover {
            color: #1a289d;
            &:after {
              background-color: black;
            }
          }
        }
      }
    }
  }
}
</style>
  