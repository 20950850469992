import { render, staticRenderFns } from "./resultado-busqueda-no-encontrado.vue?vue&type=template&id=3071b350&scoped=true&"
import script from "./resultado-busqueda-no-encontrado.vue?vue&type=script&lang=js&"
export * from "./resultado-busqueda-no-encontrado.vue?vue&type=script&lang=js&"
import style0 from "./resultado-busqueda-no-encontrado.vue?vue&type=style&index=0&id=3071b350&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3071b350",
  null
  
)

export default component.exports